define("ember-metrics/metrics-adapters/google-analytics", ["exports", "@ember/utils", "@ember/debug", "@ember/string", "ember-metrics/-private/utils/object-transforms", "ember-metrics/-private/utils/remove-from-dom", "ember-metrics/metrics-adapters/base"], function (_exports, _utils, _debug, _string, _objectTransforms, _removeFromDom, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class GoogleAnalytics extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "gaSendKey", 'send');
    }
    toStringExtension() {
      return 'GoogleAnalytics';
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      const config = _objectSpread({}, this.config);
      const {
        id,
        sendHitTask,
        trace,
        require,
        debug,
        trackerName
      } = config;
      if (trackerName) {
        this.gaSendKey = "".concat(trackerName, ".send");
      }
      (false && !(id) && (0, _debug.assert)("[ember-metrics] You must pass a valid `id` to the ".concat(this.toString(), " adapter"), id));
      delete config.id;
      delete config.require;
      delete config.debug;
      delete config.sendHitTask;
      delete config.trace;
      delete config.trackerName;
      const hasOptions = (0, _utils.isPresent)(Object.keys(config));
      this._injectScript(debug);
      if (trace === true) {
        window.ga_debug = {
          trace: true
        };
      }
      window.ga('create', id, hasOptions ? config : 'auto', trackerName);
      if (require) {
        require.forEach(plugin => {
          window.ga('require', plugin);
        });
      }
      if (sendHitTask === false) {
        window.ga('set', 'sendHitTask', null);
      }
    }

    /* eslint-disable */
    // prettier-ignore
    _injectScript(debug) {
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments);
        }, i[r].l = 1 * new Date();
        a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', "https://www.google-analytics.com/analytics".concat(debug ? '_debug' : '', ".js"), 'ga');
    }
    /* eslint-enable */

    identify() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const {
        distinctId
      } = compactedOptions;
      window.ga('set', 'userId', distinctId);
    }
    trackEvent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const sendEvent = {
        hitType: 'event'
      };
      const eventKeys = ['category', 'action', 'label', 'value'];
      let gaEvent = {};
      if (compactedOptions.nonInteraction) {
        gaEvent.nonInteraction = compactedOptions.nonInteraction;
        delete compactedOptions.nonInteraction;
      }
      for (let key in compactedOptions) {
        if (eventKeys.includes(key)) {
          const capitalizedKey = (0, _string.capitalize)(key);
          gaEvent["event".concat(capitalizedKey)] = compactedOptions[key];
        } else {
          gaEvent[key] = compactedOptions[key];
        }
      }
      const event = _objectSpread(_objectSpread({}, sendEvent), gaEvent);
      const gaSendKey = this.gaSendKey;
      window.ga(gaSendKey, event);
      return event;
    }
    trackPage() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const sendEvent = {
        hitType: 'pageview'
      };
      const event = _objectSpread(_objectSpread({}, sendEvent), compactedOptions);
      for (let key in compactedOptions) {
        // eslint-disable-next-line
        if (compactedOptions.hasOwnProperty(key)) {
          window.ga('set', key, compactedOptions[key]);
        }
      }
      const gaSendKey = this.gaSendKey;
      window.ga(gaSendKey, event);
      return event;
    }
    willDestroy() {
      (0, _removeFromDom.default)('script[src*="google-analytics"]');
      delete window.ga;
    }
  }
  _exports.default = GoogleAnalytics;
});