define("ember-click-outside/modifier", ["exports", "@ember/runloop", "@ember/destroyable", "ember-modifier", "ember-click-outside/utils"], function (_exports, _runloop, _destroyable, _emberModifier, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ClickOutsideModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "didSetup", false);
      _defineProperty(this, "action", null);
      _defineProperty(this, "capture", null);
      _defineProperty(this, "eventHandler", null);
      _defineProperty(this, "eventType", 'click');
      _defineProperty(this, "exceptSelector", null);
      _defineProperty(this, "cancelOutsideListenerSetup", null);
      _defineProperty(this, "cleanup", () => {
        this._destroy();
        if ((0, _utils.ios)()) {
          document.body.style.cursor = '';
        }
      });
      (0, _destroyable.registerDestructor)(this, this.cleanup);
    }
    modify(element, _ref, _ref2) {
      let [action] = _ref;
      let {
        capture,
        eventType,
        exceptSelector
      } = _ref2;
      if (!this.didSetup) {
        if ((0, _utils.ios)()) {
          document.body.style.cursor = 'pointer';
        }
        this.didSetup = true;
      } else {
        this._destroy();
      }
      this._init({
        element,
        action,
        capture,
        eventType,
        exceptSelector
      });
    }
    _init(_ref3) {
      let {
        element,
        action,
        capture,
        eventType,
        exceptSelector
      } = _ref3;
      if (!action) {
        return;
      }
      this.action = action;
      this.exceptSelector = exceptSelector;
      this.capture = capture;
      if (eventType) {
        this.eventType = eventType;
      }
      this.eventHandler = this._createHandler(element, this.action, this.exceptSelector);
      this.cancelOutsideListenerSetup = (0, _runloop.next)(this, this._addClickOutsideListener);
    }
    _destroy() {
      if (!this.action) {
        return;
      }
      (0, _runloop.cancel)(this.cancelOutsideListenerSetup);
      this._removeClickOutsideListener();
    }
    _addClickOutsideListener() {
      let {
        capture,
        eventHandler,
        eventType
      } = this;
      document.addEventListener(eventType, eventHandler, {
        capture
      });
    }
    _removeClickOutsideListener() {
      let {
        capture,
        eventHandler,
        eventType
      } = this;
      document.removeEventListener(eventType, eventHandler, {
        capture
      });
    }
    _createHandler(element, action, exceptSelector) {
      return e => {
        if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
          return;
        }
        let path = e.path || e.composedPath && e.composedPath();
        if (path) {
          path.includes(element) || action(e);
        } else {
          // Check if the click target still is in the DOM.
          // If not, there is no way to know if it was inside the element or not.
          let isRemoved = !e.target || !(0, _utils.documentOrBodyContains)(e.target);

          // Check the element is found as a parent of the click target.
          let isInside = element === e.target || element.contains(e.target);
          if (!isRemoved && !isInside) {
            action(e);
          }
        }
      };
    }
  }
  _exports.default = ClickOutsideModifier;
});